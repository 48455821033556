<template>
  <router-view :key="$route.fullPath" />
</template>

<style>
/* #app { */
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* font-family: 'Droid Arabic Kufi', serif; */
/* } */
</style>
