<template>
   <div class="iq-sidebar">
         <div class="iq-sidebar-logo d-flex justify-content-between">
            <router-link class="header-logo" to="/">
               <img src="../../assets/images/AlhidaiaLogo.svg" class="img-fluid rounded-normal" alt="">
               <div class="logo-title">
                  <span class="text-primary text-uppercase">الهداية</span> 
               </div>
            </router-link>
            <div class="iq-menu-bt-sidebar">
               <div class="iq-menu-bt align-self-center">
                  <div class="wrapper-menu">
                     <div class="main-circle"><i class="las la-bars"></i></div>
                  </div>
               </div>
            </div>
         </div>
         <div id="sidebar-scrollbar">
            <nav class="iq-sidebar-menu">
               <ul id="iq-sidebar-toggle" class="iq-menu FontFamily" >
                  <li class="active active-menu">
                     <router-link class="iq-waves-effect" to="/"><i class="las la-home iq-arrow-left"></i><span>الصفحة الرئيسية</span></router-link>
                  </li>
                  <li v-for="cat in catogerys" :key="cat.id">
                     <router-link :to="{ name: 'Catogerys', params: {id: cat.catogeryId } }" class="iq-waves-effect"><i class="las la-headphones"></i><span>{{ cat.name_ar }}</span></router-link>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
      <!-- TOP Nav Bar -->
      <div class="iq-top-navbar">
         <div class="iq-navbar-custom">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
               <div class="iq-menu-bt d-flex align-items-center">
                  <div class="wrapper-menu">
                     <div class="main-circle"><i class="las la-bars"></i></div>
                  </div>
                  <div class="iq-navbar-logo d-flex justify-content-between">
                     <router-link class="header-logo" to="/">
                        <img src="../../assets/images/AlhidaiaLogo.svg" class="img-fluid rounded-normal" alt="">
                        <div class="pt-2 pl-2 logo-title">
                           <span class="text-logo-tital text-uppercase">الهداية</span>
                        </div>
                     </router-link>
                  </div>
               </div>
               
                  <ul class="navbar-nav ml-auto navbar-list" style="display: flex;flex-direction: row;">
                     <div class="d-flex align-items-center">
                        <router-link aria-current="page" to="/" class="router-link-active router-link-exact-active btn btn-primary iq-play mr-2">اشتراك</router-link>
                        </div>
                        <div class="d-flex align-items-center">
                           <router-link aria-current="page" to="/" class="router-link-active router-link-exact-active btn btn-primary iq-play mr-2">تسجيل دخول</router-link>
                           </div>
                           <li class="nav-item nav-icon"><a href="#" class="search-toggle iq-waves-effect text-black rounded">
                              <i class="las la-cog"></i><span class="dots"></span></a>
                              <div class="iq-sub-dropdown iq-user-dropdown">
                                 <div class="iq-card shadow-none m-0">
                                    <div class="iq-card-body p-0">
                              <div class="d-inline-block w-100 text-center p-3">
                                 <router-link aria-current="page" to="/" class="router-link-active router-link-exact-active iq-sign-btn" role="button">تسجيل خروج<i class="ri-login-box-line ml-2">
                                    </i>
                                 </router-link></div>
                                 </div></div>
                                    </div>
                                    </li>
                                    </ul>
               
               
            </nav>
         </div>
      </div>
</template>

<script>
import { mapState } from "vuex";
export default {
   computed: {
     ...mapState('catogerys', ['catogerys'])
  },
  created() {
     this.$store.dispatch('catogerys/CatogerysAll');
  },
}
</script>

<style>

</style>