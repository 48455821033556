<template>
    <div class="hello">
    <!-- <div><button @click="preve">القطعة السابقة</button><button ref="play" @click="playAudio">لعب</button><button @click="play">لعب</button><button @click="pause">وقفة</button><button @click="next">الأغنية التالية</button> <span>برنامج{{progress}}%</span> الصوت<input v-model="volume" type="text"></div> -->
    <ul>
      <li v-for="(v,k) in srcArr" :key="k" @click="setIndex(k)">{{ v.title_ar}}</li>
    </ul>
    <audio ref='audio' autobuffer="true" :src="src"></audio>
  </div>
      <footer class="iq-footer">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row">
                  <div class="details col-6 col-sm-4 col-md-4 col-lg-4">
                     <div class="now-playing" dir="ltr"></div>
                     <div class="track-art"></div>
                     <div>
                        <div class="track-name">{{ name_ar }} </div>
                        <div class="track-artist">{{ title_ar }}</div>
                     </div>
                  </div>
                  <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                     <div class="current-time">{{ duration }}</div>
                     <input type="range" v-model="seek" class="seek_slider">
                     <!-- <div class="progress-handle" :style="{left:progressPercentageValue}"></div>

                  <div class="transparent-seeker-layer" @click="seek"></div>

                  <div class="bar" :style="{width:progressPercentageValue}"></div> -->
                     <div class="total-duration">{{ currentTime }}</div>
                  </div>
                  <div class="buttons col-6  col-sm-3 col-md-2  col-lg-2">
                     <div class="prev-track" @click="preve"><i class="fa fa-step-backward fa-2x"></i></div>
                     <div class="playpause-track" >
                         <i class="fa fa-play-circle fa-3x" v-if="!pauseTrack" @click="play"></i>
                         <i class="fa fa-pause-circle fa-3x" v-else @click="pause"></i>
                      </div>
                     <div class="next-track" @click="next"><i class="fa fa-step-forward fa-2x"></i></div>
                  </div>
                  <div class="slider_container sound col-sm-6 col-md-2  col-lg-2">
                     <i class="fa fa-volume-down"></i>
                     <input type="range" v-model="volume" class="volume_slider">
                     <i class="fa fa-volume-up"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </footer>
</template>

<script>
import { HTTP } from '@/Api/http-common';
export default {
   data() {
      return {
        msg: 'Welcome to Your Vue.js App',
        srcArr: [],
        currIndex: 0,
        currentTime: 0,
        duration: 0,
        timer: null,
        progress: 0,
        volume: 50,
        pauseTrack: false,
        sliderBtn: 0,
        step: 0,
        seek: 0,
      }
    },
    watch: {
      'currIndex': {
        handler() {
          this.$nextTick(() => {
            this.play()
          })
        }
      },
      'volume': {
        handler(val) {
          this.$refs.audio.volume = val / 100
        }
      },
      'seek': {
        handler(vals) {
          this.$refs.audio.seek = vals / 100
        }
      }
    },
    beforeCreated() {
      this.audio = new window.Audio()
      console.log(this.audio)
    },
    computed: {
      src() {
        return this.srcArr[this.currIndex].source
      },
      title_ar() {
        return this.srcArr[this.currIndex].title_ar
      },
      name_ar() {
        return this.srcArr[this.currIndex].name_ar
      }
    },
    methods: {
      formatTime(secs) {
            var minutes = Math.floor(secs / 60) || 0;
            var seconds = Math.floor(secs - minutes * 60) || 0;

            return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
      },
      initData: function () {
          HTTP.get(`CatogeryById.php?LIMIT=20&Cat_id=4`).then((response) => {
          this.srcArr = response.data.readPost;});
      },
      setIndex(k) {
        this.$nextTick(() => {
          this.play()
        })
        this.currIndex = k
      },
      play() {
        console.log('123', this.$refs.audio.load())
        this.pauseTrack = true,
        this.$refs.audio.addEventListener("canplay", () => {
            console.log('اكتمل التحميل')
            this.$refs.audio.play()
            this.duration = this.formatTime(this.$refs.audio.duration)
            console.log(this.$refs.audio.duration)
            this.timer = setInterval(() => {
            this.currentTime = this.formatTime(this.$refs.audio.currentTime)
            this.progress = this.formatTime(this.currentTime / this.duration * 100)
            if (this.currentTime / this.duration === 1) {
              clearInterval(this.timer)
            }
          }, 1000)
          },
          false);
        this.$refs.audio.volume = this.volume / 100
        this.$refs.audio.seek = this.seek / 100
        this.$nextTick(() => {
          
        })
      },
      pause() {
        this.$refs.audio.pause()
        this.pauseTrack = false; 
        clearInterval(this.timer)
      },
      _handleLoaded() {},
      next() {
        if (this.currIndex < this.srcArr.length - 1) {
          this.currIndex++
        } else {
          this.currIndex = 0
        }
      },
      preve() {
        if (this.currIndex > 0) {
          this.currIndex--
        } else {
          this.currIndex = this.srcArr.length - 1
        }
      },
      playAudio() {
        if (this.$refs.audio.paused) {
          this.$refs.play.innerHTML = 'وقفة'
          this.play()
        } else {
          this.$refs.play.innerHTML = 'لعب'
          this.pause()
        }
      },
    
      // stepFunction(){
            
      //       var sound = this.srcArr[this.currIndex].howl;
      //       var seek = sound.seek();
      //       this.timer = this.formatTime(Math.round(seek)); 
      //       this.step = (seek * 100) / sound.duration();
            
      //       this.sliderBtn = (this.progress.offsetWidth * (this.step/100) + this.progress.offsetWidth * 0.05 - 25);
            
      //       if (sound.playing()) {
      //           window.requestAnimationFrame(this.stepFunction.bind(this));
      //       }
    
      //   },
      //   seek(event){

      //       var per =  event.offsetX / this.progress.clientWidth; 
            
      //       var sound = this.srcArr[this.currIndex].howl
            
      //       if (sound) {
      //           if (sound.playing()) {
      //               sound.pause();
      //               sound.seek(sound.duration() * per);
      //               var barWidth = (per * 100) / 100;
      //               this.sliderBtn = (this.progress.offsetWidth * barWidth + this.progress.offsetWidth * 0.05 - 25);
      //               sound.play();  
      //           }else{
                    
      //               sound.seek(sound.duration() * per);
      //               // var barWidth = (per * 100) / 100;
      //               this.sliderBtn = (this.progress.offsetWidth * barWidth + this.progress.offsetWidth * 0.05 - 25);
      //           }
                
                
      //       }
      //   }
    },
    created() {
        this.initData();
    },
    mounted() {
      // console.log(this.audio)
      // this.audio.addEventListener('loadeddata', this._handleLoaded)
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    font-family: 'Poppins', sans-serif;
}
#journal-scroll::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;

}
#journal-scroll::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
}
#journal-scroll::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    
}
.cd-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f7fafc;
}

#progressButtonTimer,#progressButtonVolume{
    margin-top:-9px;
    right:-8px;
}

@media screen and (max-width: 768px) {
  #progressButtonTimer, #progressButtonVolume{
    margin-top: -8px;
    right: -7px;
  }
}
</style>