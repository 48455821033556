<template>
  <div class="container-fluid">
        <div class="row">
            <FirstHomePage />
        </div>
  </div>
</template>

<script>
import FirstHomePage from "./FirstHomePage.vue";
export default {
   components: {
    FirstHomePage
  }
}
</script>

<style>

</style>