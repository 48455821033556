import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import VueLazyload from 'vue-lazyload'
import fastclick from 'fastclick'
window.$ = window.jQuery = require('jquery');

fastclick.attach(document.body)

const loadimage = require('./components/Skeleton/Loadings.vue')
const errorimage = require('./components/Skeleton/Loadings.vue')

createApp(App)
    .use(store)
    .use(router)
    .use(VueCookieNext)
    .use(VueLazyload, {
        preLoad: 1.3,
        error: errorimage,
        loading: loadimage,
        attempt: 1
    })
    .mount('#app')
