import { createStore } from 'vuex'
import catogerys from './modules/catogerys'
export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    catogerys
  }
})
