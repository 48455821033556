<template>
     <div class="row row--grid">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <h4 class="card-title font-weight-600 FontFamily">الـــهداية</h4>
          </div>
        </div>
        <div class="iq-card-body">
          <ul class="list-unstyled row iq-box-hover mb-0">
            <li
              class="col-6 col-sm-4 col-lg-3 col-xl-3 iq-music-box"
              v-for="post in catogerys" :key="post.id"
            >
              <div class="iq-card">
                <div class="iq-card-body p-0">
                  <div class="iq-thumb">
                    <div class="iq-music-overlay"></div>
                    <!-- <a href="music-player.html"> -->
                    <!-- <a @click="getFeed(play)"> -->
                      <img
                        v-lazy="post.coverimage"
                        class="img-border-radius img-fluid w-100"
                        alt=""
                      />
                    <!-- </a> -->
                    <!-- </a> -->
                    <div class="overlay-music-icon">
                      <a @click="getFeed(post)">
                        <i class="las la-play-circle"></i>
                      </a>
                    </div>
                  </div>
                  <div class="feature-list text-center">
                    <h6 class="font-weight-600 mb-0 FontFamily">
                      {{ post.name_ar }}
                    </h6>
                    <p class="mb-0 FontFamily">{{ post.name_en }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { HTTP } from '@/Api/http-common';
// import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { mapState } from "vuex";
import { useCookie } from 'vue-cookie-next';
export default {
    computed: {
     ...mapState('catogerys', ['catogerys'])
    },
    created() {
      this.$store.dispatch('catogerys/CatogerysAll');
    },
   async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const getFeed = (post) => {
          cookie.setCookie('cat_id', post.catogeryId)
          router.push({ name: "Catogerys", params: { id: post.catogeryId  } });
        };    
      return {
        getFeed
      };
    },
}
</script>

<style>

</style>